<script>
  import Project from "./Project.svelte";

  const projects = [
    {
      "id": 1,
      "title": "Flytelog",
      "description": "This project was developed by 5 people over the course of five weeks. While my focus was on devops, form building, and styling, I contributed to all parts of this project.",
      "deployed_link": "https://lab9-flightlogs.firebaseapp.com/",
      "github_link": "https://github.com/Lambda-School-Labs/labs9-flight-log",
      "image": "Flytelog_Screenshot.PNG"
    },
    {
      "id": 2,
      "title": "UseMyStuff",
      "description": "This project was developed by 7 people over the course of four days. My role as team leader was to facilitate communication between the developers who were at different stages in their education.",
      "deployed_link": "https://bw2-techstuff.netlify.com/",
      "github_link": "https://github.com/lambda-build-week-2-use-my-tech-stuff"
    },
    {
      "id": 3,
      "title": "Lambda Notes",
      "description": "This project was developed by me alone over two five day sessions - one for the front end and one for the backend.",
      "deployed_link": "",
      "github_link": "https://github.com/ssmodish/lambda-notes-redux"
    }
  ]
</script>

<style>
  .projects {
    min-height: 50vh;
  }

  a {
    font-size: 20px;
    color: #dedede;
  }
</style>

<div class="projects">
  <a href="projects" name="projects">Projects</a>

  {#each projects as project}
  <Project {project} />
    
  {/each}
</div>
