<script>
  export let project;
</script>


<style>
  .project {
    height: 30vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    margin: 10px;
  }

  p {
    color: #333;
  }

  button {
    background-color: rgba(0, 0, 0, 0.6);
    margin: 15px;
    padding: 15px;
  }

  button a {
    color: #dedede;
  }
</style>

<div class="project">
  <a href="project" name="project">
    <h1>{project.title}</h1>
  </a>
  <p>{project.description}</p>
  <button><a href={project.deployed_link}>Visit Page</a></button>
  <button><a href={project.github_link}>View Code</a></button>

</div>
