<script>
  let contact = {
    "email": "stevesmodish@gmail.com",
    "github": "https://github.com/ssmodish",
    "linkedin": "https://www.linkedin.com/in/stevesmodish/",
    "homepage": "https://www.stevesmodish.com"
  }
</script>

<style>
  .contact {
    height: 100vh;
  }
  a {
    margin: 0 14px;
  }
</style>

<div class="contact">
  <h1><a href="contact" name="contact">Contact</a></h1>
  <a href={contact.email}><span class="service_logo" id="email"></span>Email</a>
  <a href={contact.github}><span class="service_logo" id="github"></span>GitHub</a>
  <a href={contact.linkedin}><span class="service_logo" id="linkedin"></span>LinkedIn</a>
  <a href={contact.homepage}><span class="service_logo" id="homepage"></span>Home</a>
</div>
